//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    BaseView: () => import("@/components/BaseView"),
    Staechart: () => import("./satechart.vue"),
    Radar: () => import("./radar"),
    tooltip: () => import("@/components/textTooltip"),
    keywordfrequency: () => import("../keywordfrequency"),
    progresscom: () => import("../progressCom"),
    ThermodynamicChart: () => import("@/components/ThermodynamicChart"),
    AllSensitiveWord: () => import("../allSensitiveWord"),
  },
  props: {
    //是否显示截图
    IsRealTimeScreenshot: {
      type: Boolean,
      default: true,
    },
    //团队id
    teamId: {
      type: Number,
      default: null,
    },
    //开始的日期
    stime: {
      type: String,
      default: null,
    },
    //结束的日期
    etime: {
      type: String | Date,
      default: null,
    },
    //列表页选择的日期
    selDate: {
      type: Date | String,
      default: null,
    },
    //列表页按周查或按月查
    dateType: {
      type: Number | String,
      default: null,
    },
    //用户id
    uid: {
      type: Number,
      default: null,
    },
    //用户名
    clickUserName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      dataDetails: null,
      behaviorArray: [],
      AppDetails: [],
      sensitiveWord: [], //敏感词
      efficiencyData: null, //雷达图
      loading: false,
      workTime: null,
      // 高频词
      ThreeTexts: [],
      // 本日工作状态占比
      echartWorkData: [],
      // 本日使用软件占比
      echartSoftWearData: [],
      /*
       * 右侧时间
       */
      ulTimeArr: [],
      activeNames: ["1"],
      selActiveTime: null,
      /*
       *时间轴与使用软件
       */
      softData: [],
      odd: [],
      noodd: [],
      clickKeyWord: null,
      selRow: null, //选择的软件
      pname: null, //选择的关键词的窗口名
    };
  },
  watch: {
    selActiveTime() {
      this.getDataInfo();
    },
  },
  computed: {
    cmurl() {
      return process.env.VUE_APP_CMURL;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.getDateList();
      this.getDataInfo();
    });
  },
  methods: {
    handleBackPage() {
      this.selActiveTime = null;
      this.$emit("pageChange", true);
    },
    /**
     * 行为分析标签颜色
     * 积极：绿  消极：红  无：白色
     */
    getTagType(val) {
      if (val == "积极") {
        return "success";
      } else if (val == "消极") {
        return "danger";
      } else {
        return "info";
      }
    },
    /**
     * 查看某个行为
     */
    handleBehavior(val) {
      this.dataDetails.Behaviors = this.behaviorArray;
      this.$emit("handleBehavior", val, this.dataDetails);
    },
    /**
     * 查看全部行为
     */
    handleAllBehavior() {
      this.dataDetails.Behaviors = this.behaviorArray;
      this.$emit("handleAllBehavior", this.dataDetails);
    },
    /**
     * 查看所有敏感词
     */
    handleAllSensitiveWords() {
      this.$modal.show("allSensitiveWords");
    },
    /**
     * 查看全部关键词
     */
    handleAllWords() {
      this.$emit("handleAllWords", this.dataDetails);
    },
    /**
     * 查看全部软件
     */
    handleAllSoftware() {
      console.log(this.selActiveTime);
      const data = {
        User: {
          id: this.uid,
        },
        selActiveTime: this.selActiveTime,
      };
      this.$emit("handleAllSoftware", data);
      // this.$modal.show("allsoftware");
    },
    /**
     * 某个软件截图
     */
    handleAppPic(app) {
      this.selRow = {
        name: app.PName,
        fromList: app.fromList,
      };
      this.$nextTick(() => {
        this.$modal.show("staffDataPic");
      });
    },
    /**
     * 获取详情数据
     */
    getDataInfo() {
      this.loading = true;
      if (this.selActiveTime) {
        this.MemberDataDetails();
      } else {
        this.DataDetailsSummary();
      }
    },
    /**
     * 具体某一天
     */
    MemberDataDetails() {
      const data1 = {
        u: this.uid,
        date: this.selActiveTime,
        teamId: this.teamId,
      };
      const data3 = {
        uid: this.uid,
        datestart: this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
      };
      const data4 = {
        usId: this.uid,
        datestart: this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
        type: 1,
      };
      const data2 = {
        usId: this.uid,
        datestart: this.selActiveTime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.selActiveTime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
      };
      Promise.all([
        this.$http.get("/Teams/MemberJob/MemberDataDetails.ashx#", {
          params: data1,
        }),
        this.$http.post("/User/Work/WorkEfficiencyAnalysis.ashx", data3),
        this.$http.get("/User/Work/GetBehaviorAnalyse.ashx", { params: data4 }),
        this.$http.post("/SensitiveWord/GetSensitiveWord.ashx", data2),
      ]).then((resp) => {
        if (resp[0].res == 0) {
          this.dataDetails = resp[0].data;
          this.dataDetails.User = {
            id: this.uid,
          };
          this.dataDetails.selActiveTime = this.selActiveTime;
          //本日工作状态占比图表数据
          this.echartWorkData = resp[0].data.ComputerUsageRecord.workRat.map(
            (m) => {
              return {
                name: m.name,
                value: m.value.toFixed(1),
              };
            }
          );
          //本日使用软件占比
          this.AppDetails = resp[0].data.AppDetails.filter(
            (m, index) => index < 6
          );
          this.echartSoftWearData = resp[0].data.AppDetails.map((m) => {
            return {
              name: m.AppName,
              value: m.ratio,
            };
          });
          //高频关键词
          if (resp[0].data.KeyWordFreqs && resp[0].data.KeyWordFreqs.length) {
            this.ThreeTexts = resp[0].data.KeyWordFreqs.filter(
              (m, index) => index < 20
            );
          }
          //时间轴与使用软件、定期截图数据
          this.softData = resp[0].data.TimeLine;
          this.workTime = resp[0].data.WorkTime;
        }
        if (resp[1].res == 0) {
          this.efficiencyData = resp[1].data;
        }
        if (resp[2].res == 0) {
          this.behaviorArray = resp[2].data.Behavior;
        }
        if (resp[3].res == 0) {
          this.sensitiveWord = resp[3].data.Behavior;
        }
        this.loading = false;
      });
    },
    /**
     * 所有时间
     */
    DataDetailsSummary() {
      const data1 = {
        u: this.uid,
        st: this.stime,
        et: this.etime,
        teamId: this.teamId,
      };
      const data3 = {
        uid: this.uid,
        datestart: this.stime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.etime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
      };
      const data4 = {
        usId: this.uid,
        datestart: this.stime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.etime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
        type: 1,
      };
      const data2 = {
        usId: this.uid,
        datestart: this.stime.timeFormat("yyyy-MM-dd 00:00:01"),
        dateend: this.etime.timeFormat("yyyy-MM-dd 23:59:59"),
        teamId: this.teamId,
      };
      Promise.all([
        this.$http.post("/User/MemberDataDetailsSummary.ashx#", data1),
        this.$http.post("/User/Work/WorkEfficiencyAnalysis.ashx", data3),
        this.$http.get("/User/Work/GetBehaviorAnalyse.ashx", { params: data4 }),
        this.$http.post("/SensitiveWord/GetSensitiveWord.ashx", data2),
      ]).then((resp) => {
        console.log(resp);
        if (resp[0].res == 0) {
          this.dataDetails = resp[0].data;
          this.dataDetails.User = {
            id: this.uid,
          };
          this.dataDetails.selActiveTime = this.selActiveTime;
          //本周/月工作状态占比图表数据
          this.echartWorkData = resp[0].data.ComputerUsageRecord.workRat.map(
            (m) => {
              return {
                name: m.name,
                value: m.value.toFixed(1),
              };
            }
          );
          //本周/月使用软件占比
          this.AppDetails = resp[0].data.AppDetails.filter(
            (m, index) => index < 6
          );
          this.echartSoftWearData = resp[0].data.AppDetails.map((m) => {
            return {
              name: m.AppName,
              value: m.ratio,
            };
          });
          //高频关键词
          if (resp[0].data.KeyWordFreqs && resp[0].data.KeyWordFreqs.length) {
            this.ThreeTexts = resp[0].data.KeyWordFreqs.filter(
              (m, index) => index < 20
            );
          }

          //时间轴与使用软件、定期截图数据
          this.softData = resp[0].data.TimeLine;
          this.workTime = resp[0].data.WorkTime;
        }

        if (resp[1].res == 0) {
          this.efficiencyData = resp[1].data;
        }
        if (resp[2].res == 0) {
          this.behaviorArray = resp[2].data.Behavior;
        }
        if (resp[3].res == 0) {
          this.sensitiveWord = resp[3].data.Behavior;
        }
        this.loading = false;
      });
    },
    /**
     * 查看某个关键词
     */
    handleKeyWord(val, type) {
      if (type == 1) {
        this.$emit("handleKeyWord", val, this.dataDetails);
      } else {
        this.$refs.allSWord.cellClick(val);
      }

      // this.clickKeyWord = val.Key;
      // this.pname = val.FocusFormName;
      // this.$modal.show("staffDataWord");
    },
    endSoftData() {
      this.softData.forEach((item) => {
        if (item.num % 2 == 0) {
          this.odd.push(item);
        } else {
          this.noodd.push(item);
        }
      });
    },
    liClick(time) {
      if (time) {
        this.selActiveTime = time;
      } else {
        this.selActiveTime = null;
      }
    },
    /**
     * 获取日期
     */
    getDateList() {
      //按周获取日期
      let arr = [];
      if (this.dateType == 1) {
        //获取周一至周六日期
        for (let i = 0; i < 7; i++) {
          let date = new Date(this.stime);
          date.setDate(date.getDate() + i);
          arr.push(date.timeFormat("yyyy-MM-dd"));
        }
        this.ulTimeArr = arr;
      } else if (this.dateType == 2) {
        //获取当月日期
        const curDate = new Date(this.selDate);
        //该月的天数
        const dayNum = new Date(
          curDate.getFullYear(),
          curDate.getMonth() + 1,
          0
        );
        //是否是本月
        const nowM = new Date().getMonth() + 1;
        const nowd = new Date().getDate();
        const isNowM = nowM == curDate.getMonth() + 1 ? true : false;
        for (let i = 1; i <= dayNum.getDate(); i++) {
          if (isNowM && nowd < i) {
            break;
          }
          let date = new Date(this.selDate);
          date.setDate(i);
          arr.push(date.timeFormat("yyyy-MM-dd"));
        }
        this.ulTimeArr = arr;
      } else {
        //按时间段
        const time = Date.parse(new Date(this.stime));
        const lasttime = Date.parse(new Date(this.etime));
        const day = parseInt((lasttime - time) / (1000 * 60 * 60 * 24));
        for (let i = 0; i <= day; i++) {
          let date = new Date(this.stime);
          date.setDate(date.getDate() + i);
          arr.push(date.timeFormat("yyyy-MM-dd"));
        }
        this.ulTimeArr = arr;
      }
    },
  },
  /*
   *初始化页面数据
   */
  created() {
    this.softData.forEach((e, index) => {
      this.$set(e, "num", index);
    });
    // 过滤奇数偶数
    this.endSoftData();
  },
};
