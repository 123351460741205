var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"baseViewPage"},[_c('div',{staticClass:"name_title"},[_c('span',{on:{"click":_vm.handleBackPage}},[_c('i',{staticClass:"el-icon-arrow-left"}),_vm._v(_vm._s(_vm.clickUserName))])]),_c('BaseView',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"staff",attrs:{"title_name":'时间'}},[_c('ul',{staticClass:"infinite-list",attrs:{"slot":"panes"},slot:"panes"},[_c('li',{class:{ activeList: _vm.selActiveTime === null },on:{"click":function($event){return _vm.liClick(null)}}},[_c('span',[_vm._v("查看所有")])]),_vm._l((_vm.ulTimeArr),function(item){return _c('li',{key:item,class:{ activeList: _vm.selActiveTime === item },on:{"click":function($event){return _vm.liClick(item)}}},[_c('span',[_vm._v(_vm._s(item))])])})],2),_c('div',{staticClass:"staffbox",attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"state"},[_c('div',{staticClass:"state_one"},[_c('div',{staticClass:"card_title_one"},[_c('h3',[_vm._v("行为热力图")]),_c('ul',{staticClass:"status"},[_c('li',[_c('span',{staticStyle:{"background":"#67c23a"}}),_vm._v("积极")]),_c('li',[_c('span',{staticStyle:{"background":"#f56c6c"}}),_vm._v("消极")]),_c('li',[_c('span',{staticStyle:{"background":"#f2f6fc"}}),_vm._v("无状态")])])]),_c('div',{staticClass:"info"},[_c('ThermodynamicChart',{attrs:{"IsRealTimeScreenshot":_vm.IsRealTimeScreenshot,"isColumn":true,"height":'260px',"width":'370px',"column":12,"row":10,"datestart":_vm.selActiveTime
                    ? _vm.selActiveTime.timeFormat('yyyy-MM-dd 00:00:01')
                    : _vm.stime.timeFormat('yyyy-MM-dd 00:00:01'),"dateend":_vm.selActiveTime
                    ? _vm.selActiveTime.timeFormat('yyyy-MM-dd 23:59:59')
                    : _vm.etime.timeFormat('yyyy-MM-dd 23:59:59'),"UsId":_vm.uid,"teamId":_vm.teamId}})],1)]),_c('div',{staticClass:"state_one"},[_c('h3',[_vm._v("操作效率雷达图")]),_c('div',{staticClass:"info"},[_c('Radar',{attrs:{"efficiencyData":_vm.efficiencyData}})],1)]),_c('div',{staticClass:"state_one state_one_three"},[_c('div',[_c('h3',[_vm._v("工作状态")]),_c('div',{staticClass:"info"},[_c('Staechart',{attrs:{"echartData":_vm.echartWorkData,"width":350,"height":120,"workTime":_vm.workTime}})],1)]),_c('div',[_c('h3',[_vm._v(" 本"),(_vm.selActiveTime)?_c('span',[_vm._v("日")]):(_vm.dateType == 1)?_c('span',[_vm._v("周")]):(_vm.dateType == 2)?_c('span',[_vm._v("月")]):_c('span',[_vm._v("时间段")]),_vm._v("使用软件占比 ")]),(_vm.echartSoftWearData && _vm.echartSoftWearData.length)?_c('div',{staticClass:"info"},[_c('Staechart',{attrs:{"echartData":_vm.echartSoftWearData,"width":350,"height":120,"workTime":_vm.workTime}})],1):_c('div',{staticClass:"work_appl_list_empty"},[_vm._v("暂无数据")])])])]),_c('div',{staticClass:"state"},[_c('div',{staticClass:"state_two"},[_c('div',{staticClass:"card_title"},[_c('h3',[_vm._v(" 行为分析"),_c('el-tooltip',{attrs:{"placement":"top","effect":"light"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('ul',{staticClass:"status_tooltip"},[_c('li',[_c('span',{staticStyle:{"background":"#67c23a"}}),_vm._v("积极")]),_c('li',[_c('span',{staticStyle:{"background":"#f56c6c"}}),_vm._v("消极")]),_c('li',[_c('span',{staticStyle:{"background":"#f2f6fc"}}),_vm._v("无状态")])])]),_c('i',{staticClass:"el-icon-question"})])],1),(_vm.behaviorArray.length)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleAllBehavior.apply(null, arguments)}}},[_vm._v("查看全部")]):_vm._e()],1),(_vm.behaviorArray.length)?_c('div',{staticClass:"keyword_list"},_vm._l((_vm.behaviorArray.filter(
                  function (m, index) { return index < 16; }
                )),function(bitem,bindx){return _c('el-tag',{key:bindx,staticClass:"word_item",attrs:{"size":"mini","type":_vm.getTagType(bitem.BehavoirType)},on:{"click":function($event){return _vm.handleBehavior(bitem)}}},[_c('tooltip',{ref:("behavoir-" + bindx),refInFor:true,attrs:{"content":("" + (bitem.Behavoir)),"width":"100%"},on:{"handleClick":function($event){return _vm.handleBehavior(bitem)}}})],1)}),1):_c('div',{staticClass:"work_appl_list_empty"},[_vm._v("暂无数据")])]),_c('div',{staticClass:"state_two"},[_c('div',{staticClass:"card_title"},[_c('h3',[_vm._v("高频关键词")]),(_vm.ThreeTexts.length)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleAllWords.apply(null, arguments)}}},[_vm._v("查看全部")]):_vm._e()],1),(_vm.ThreeTexts && _vm.ThreeTexts.length)?_c('div',{staticClass:"info"},_vm._l((_vm.ThreeTexts),function(item,wordindex){return _c('tooltip',{key:wordindex,ref:("demandLeftMenu-" + wordindex),refInFor:true,staticClass:"i_text",attrs:{"content":item.Key,"maxWidth":"40%"},on:{"handleClick":function($event){return _vm.handleKeyWord(item, 1)}}})}),1):_c('div',{staticClass:"work_appl_list_empty"},[_vm._v("暂无数据")])])]),_c('div',{staticClass:"state"},[_c('div',{staticClass:"state_two"},[_c('div',{staticClass:"card_title"},[_c('h3',[_vm._v("常用应用")]),(_vm.AppDetails.length)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleAllSoftware.apply(null, arguments)}}},[_vm._v("查看全部")]):_vm._e()],1),(_vm.AppDetails.length)?_c('div',{staticClass:"work_appl_list"},_vm._l((_vm.AppDetails),function(appitem,ind){return _c('div',{key:ind,staticClass:"w_a_l_card"},[_c('p',{staticClass:"time"},[_vm._v(_vm._s(appitem.StayTime)+"H")]),_c('div',{staticClass:"card_proportion"},[_c('div',{staticClass:"proportion",style:(("height:" + (_vm.workTime > 0
                        ? (appitem.StayTime / _vm.workTime) * 100 >= 100
                          ? 100
                          : (appitem.StayTime / _vm.workTime) * 100
                        : (6 - ind) * 20) + "%"))})]),_c('p',{staticClass:"card_name"},[_c('tooltip',{ref:("memprop-" + ind),refInFor:true,attrs:{"content":("" + (appitem.AppName)),"width":"98%"}})],1)])}),0):_c('div',{staticClass:"work_appl_list_empty"},[_vm._v("暂无数据")])]),_c('div',{staticClass:"state_two"},[_c('div',{staticClass:"card_title"},[_c('h3',[_vm._v("敏感词汇")]),(_vm.sensitiveWord.length)?_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){$event.stopPropagation();return _vm.handleAllSensitiveWords.apply(null, arguments)}}},[_vm._v("查看全部")]):_vm._e()],1),(_vm.sensitiveWord && _vm.sensitiveWord.length)?_c('div',{staticClass:"info"},_vm._l((_vm.sensitiveWord.filter(
                  function (m, index) { return index < 20; }
                )),function(sitem,swordindex){return _c('tooltip',{key:swordindex,ref:("sensitiveWord-" + swordindex),refInFor:true,staticClass:"i_text",attrs:{"content":sitem.Sensitive,"maxWidth":"40%"},on:{"handleClick":function($event){return _vm.handleKeyWord(sitem, 2)}}})}),1):_c('div',{staticClass:"work_appl_list_empty"},[_vm._v("暂无数据")])])]),(_vm.softData && _vm.softData.length)?_c('div',{staticClass:"soft"},[_c('div',{staticClass:"title"},[_vm._v("时间轴与使用软件")]),(_vm.softData && _vm.softData.length)?_c('div',{staticClass:"softbox"},_vm._l((_vm.softData),function(item,softind){return _c('div',{key:item.Line,class:{
                speed_footer: softind % 2 == 0,
                speed_header: softind % 2 !== 0,
              }},[_c('div',{staticClass:"left"},[_c('span',{staticClass:"time",style:(("" + (!_vm.selActiveTime ? 'width:65px' : '')))},[_vm._v(_vm._s(item.Line))]),_c('ul',{staticClass:"s_l_ul"},_vm._l((item.Process),function(citem,csoftind){return _c('li',{key:csoftind,staticClass:"s_l_li"},[_c('tooltip',{ref:("process-" + csoftind),refInFor:true,staticClass:"i_text",attrs:{"content":citem.PName,"maxWidth":"70%"},on:{"handleClick":function($event){return _vm.handleAppPic(citem)}}}),_c('span',[_vm._v("("+_vm._s((citem.PNamePercent * 100).toFixed(0))+"%)")])],1)}),0)]),_c('div',{staticClass:"right"})])}),0):_c('div',{staticClass:"nodata"},[_c('i',{staticClass:"hiFont hi-wushuju"}),_c('p',[_vm._v("暂无数据")])])]):_vm._e(),(
            _vm.IsRealTimeScreenshot &&
            _vm.softData &&
            _vm.softData.filter(function (m) { return m.Img; }).length
          )?_c('div',{staticClass:"scree"},[_c('div',{staticClass:"scree_title"},[_vm._v("定期截图")]),(_vm.softData && _vm.softData.filter(function (m) { return m.Img; }).length)?_c('div',[_c('viewer',{staticClass:"screephot",attrs:{"images":_vm.softData.filter(function (m) { return m.Img; })}},_vm._l((_vm.softData.filter(function (m) { return m.Img; })),function(itempic,picindex){return _c('div',{key:picindex,staticClass:"screen"},[_c('img',{staticStyle:{"width":"157px","height":"88px"},attrs:{"src":_vm.cmurl + itempic.Img,"alt":itempic.FocusFromName}}),_c('p',{staticClass:"time"},[_vm._v(_vm._s(itempic.Line))])])}),0)],1):_c('div',{staticClass:"nodata"},[_c('i',{staticClass:"hiFont hi-wushuju"}),_c('p',[_vm._v("暂无数据")])])]):_vm._e()])]),_c('progresscom',{attrs:{"IsRealTimeScreenshot":_vm.IsRealTimeScreenshot,"name":'staffDataPic',"activeBar":_vm.selRow,"stime":_vm.selActiveTime,"etime":_vm.selActiveTime,"uid":_vm.uid,"teamValue":_vm.teamId}}),_c('AllSensitiveWord',{ref:"allSWord",attrs:{"IsRealTimeScreenshot":_vm.IsRealTimeScreenshot,"sensitiveWord":_vm.sensitiveWord,"stime":_vm.selActiveTime
          ? _vm.selActiveTime.timeFormat('yyyy-MM-dd 00:00:01')
          : _vm.stime.timeFormat('yyyy-MM-dd 00:00:01'),"etime":_vm.selActiveTime
          ? _vm.selActiveTime.timeFormat('yyyy-MM-dd 23:59:59')
          : _vm.etime.timeFormat('yyyy-MM-dd 23:59:59'),"uid":_vm.uid,"teamValue":_vm.teamId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }